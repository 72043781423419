<template>
    <!-- 导航 -->
    <page-head :title="route.query.pageType === 'playerEval' ? '球员评估' : '球员信息'">
        <template #right>
            <div class="filter_box" v-if="teamList.length">
                <select-style
                    :list="teamList"
                    v-model:value="team"
                    @changeFn="changeFn($event, 'Filter')"
                    skin="white"
                ></select-style>
            </div>
        </template>
    </page-head>
    <!-- 列表 -->
    <player-list :list="playerData.showList" @player-click="playerClick"/>
    <!-- 翻页 -->
    <pagination-com
        v-if="playerData.showList && playerData.showList.length > 0"
        class="pagination"
        :current-page="playerData.page"
        :total-page="playerData.totalPage"
        @changePage="changePage"
    />
</template>

<script>
import {useRouter, useRoute, onBeforeRouteLeave} from "vue-router";
import {defineComponent, onMounted, getCurrentInstance, toRefs, reactive, watch, computed} from 'vue';
import {useStore} from "vuex";

export default defineComponent({
    setup() {
        const router = useRouter()
        const route = useRoute()
        const {proxy} = getCurrentInstance();
        const store = useStore();
        const state = reactive({
            teamList: store.state.teamData.teensCampTeamList ? store.state.teamData.teensCampTeamList : [],
            team: store.state.teamData.teensCampTeamList ? store.state.teamData.teensCampTeamList[0] : '',
            playerData: {}, // 页面数据
            serverData: {}, // 服务端数据
            query: {
                pageNum: 1,
                pageSize: 12
            },
        });
        watch(() => store.state.identifyData, (val, old) => {
            // console.log(val, old);
            if (!val.type) return false;
            const params = {
                ...val,
                ...state.playerData,
            }
            proxy.$utils.identifyFn({
                type: val.type,
                data: params,
                changePage: changePage,
                router: playerClick
            })
        })

        /** 获取球员列表 **/
        const getData = async () => {
            const {id} = store.state.teamData;
            let params = {}

            if (route.query.type === 'camp') {
                params['teensCampId'] = id;
                params['teensCampTeamId'] = state.team.id;
            } else if (route.query.type === 'team') {
                params['teamId'] = id
            }

            const {
                code,
                data
            } = await (route.query.type === 'team' ? proxy.$server.getPlayersList(params) : proxy.$server.getCampPlayer(params));
            if (code === 200) {
                const {pageNum, pageSize} = state.query;
                state.serverData = data;
                state.playerData = proxy.$utils.paginationFn(data, pageNum, pageSize);
            }
        };

        const changePage = (page) => {
            const {pageSize} = state.query;
            state.playerData = proxy.$utils.paginationFn(state.serverData, page, pageSize);
        }

        const playerClick = (row) => {
            const pageType = route.query.pageType
            let path = '/playerList/details'
            // 球员评估
            if (pageType === 'playerEval') {
                path = '/playerList/evalDetail'
            }

            let campId = {}
            if (route.query.type === 'camp') {
                campId = {
                    campId: store.state.teamData.id
                }
            }


            router.push({
                path,
                query: {
                    ...campId,
                    id: row.id,
                    name: row.name,
                    number: row.number,
                    position: row[proxy.$Config.POSITION],
                }
            })
        }

        const queryParams = computed(() => {
            return store.state.queryParams;
        });

        const changeFn = (item) => {
            state.team = item;
            getData()
        }

        onMounted(() => {
            if (queryParams.value.page) {
                state.query.pageNum = queryParams.value.page
            }
            getData();
        });

        onBeforeRouteLeave((to, from, next) => {
            console.log(to)
            let params = {
                page: state.query.pageNum
            }
            if (to.name === 'playerDetails' || to.name === 'playerEvalDetail') {
                store.commit('setQueryParams', params);
            } else {
                params.page = 1
                store.commit('setQueryParams', params);
            }
            next()
        })

        return {
            ...toRefs(state),
            route,
            changePage,
            playerClick,
            changeFn
        }
    },
})
</script>

<style scoped lang="scss">
.pagination {
    margin-left: 590px;
}

.filter_box {
    margin-left: 820px;
}
</style>

